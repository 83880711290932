import React from "react";
import { Table } from "react-bootstrap";

function preventDefault(event) {
  event.preventDefault();
}

const df = (today) => {
  const y = today.getFullYear();
  const m =
    today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1;
  const d = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  return y + "-" + m + "-" + d;
};
const headRowArr = [
  df(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 4 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)),
  df(new Date(Date.now() - 0 * 24 * 60 * 60 * 1000)),
];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function DxTable({ data, data2 }) {
  const getColData = (key) => {
    let list = [];

    headRowArr.map((v, i) => {
      let j = 0;
      if (data2[key].job[v]) {
        j = Number(data2[key].job[v]);
        // list.push(<TableCell align={'center'}>{data2[key].job[v]}</TableCell>)
      } else {
        j = 0;
      }

      let t = 0;
      if (data2[key].transport[v]) {
        t = Number(data2[key].transport[v]);
      } else {
        t = 0;
      }

      list.push(
        <td
          key={i}
          align={"center"}
        >{`${j.toLocaleString()} / ${t.toLocaleString()}`}</td>
      );
    });

    return list;
  };
  const getCol = () => {
    let list = [];
    Object.keys(data2).forEach(function (key, index) {
      // console.log(key);
      list.push(
        <tr key={key}>
          <td key={key} style={{ color: "#1769aa" }}>
            {key.toUpperCase()}
          </td>
          {getColData(key)}
        </tr>
      );
    });

    return list;
  };
  const reDate = (val) => {
    const n = new Date(val);
    const fD = n.getDate() > 9 ? n.getDate() : "0" + n.getDate();
    return fD + " " + months[n.getMonth()];
  };
  return (
    <Table striped="columns">
      <thead>
        <tr>
          <th style={{ textAlign: "center" }} align={"center"}>
            Project
          </th>
          {headRowArr.map((val) => (
            <th style={{ textAlign: "center" }} align={"center"} key={val}>
              {reDate(val)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{data && getCol()}</tbody>
    </Table>
  );
}
