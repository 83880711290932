import React from "react";
import { Chart } from "react-google-charts";

export default function DxChart({ data }) {
  // useEffect(() => {

  //     console.log(data);
  //     return () => {

  //     }
  // }, [])

  return (
    <Chart
      chartType="AreaChart"
      data={[["Date", "Total"], ...data]}
      width="100%"
      legendToggle
      options={{
        legend: "none",
      }}
    />
  );
}
