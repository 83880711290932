import React from 'react'
import { Card } from 'react-bootstrap'

export default function CardDashboard({ title, jobCount, last, all, children }) {
    const [yesterDay, setYesterDay] = React.useState('')
    React.useEffect(() => {
        setYesterDay(((jobCount - last) * 100 / last).toFixed(0))
        // setYesterDay(((last - jobCount) * 100 / last).toFixed(0))
        return () => {

        }
    }, [jobCount])
    return (
        <Card>
            <Card.Body>
                <Card.Title className='text-primary'>{title}</Card.Title>
                <h2>
                    <Card.Text>

                        {jobCount.toLocaleString()}
                        {all ? `/${all}` : ''}

                    </Card.Text>
                </h2>
                {
                    last &&
                    <Card.Text sx={{ flex: 1, marginTop: '0.5em' }}>
                        <span style={{ color: yesterDay > 0 ? 'green' : 'red' }}>{yesterDay > 0 ? '+' + yesterDay : yesterDay}%</span> than last day
                    </Card.Text>
                }
                {children}
            </Card.Body>
        </Card>
    )
}
