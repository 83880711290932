import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { BASE_URL } from "../App";
import Loading from "../components/Loading";

export default function SslPage() {
  const [data, setData] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await axios.post(`${BASE_URL}/ssl/get_ssl_data`);

    setData(result.data.data);
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <Container className="px-4 py-2">
      <h3>SSL</h3>
      <code className="m-2">
        command renew: certbot --apache -d 'full_domain_name'
      </code>
      <Table striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th>Domain Name</th>
            <th>Provider</th>
            <th>Issue On</th>
            <th>Expire</th>
            <th>Remain</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={item.url}>
                <td>{index + 1}</td>
                <td
                  className={
                    item.url.search("dxplace.com") >= 0
                      ? "bg-info"
                      : item.url.search("dxtms.com") >= 0
                      ? "bg-warning"
                      : ""
                  }
                >
                  {item.url}
                </td>
                <td>{item.provider}</td>
                <td>{item.issue_on}</td>
                <td>{item.expire_on}</td>
                <td className={item.is_alert ? "text-danger" : ""}>
                  {item.remain}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
