import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {/* <Spinner animation="border" role="status" color='green'>
                <span className="visually-hidden">Loading...</span>
            </Spinner> */}
            <Spinner animation="grow" variant="primary" />

        </div>
    );
};

export default Loading;
