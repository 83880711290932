import axios from "axios";
import React, { useRef, useState } from "react";
import { Button, Card, Container, Form, Image } from "react-bootstrap";
import { BASE_URL } from "../App";
import logo from "../assets/logo_dx.png"; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAuth from "../hooks/useAuth";

const LoginPage = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    if (username.trim().length == 0 || password.trim().length == 0) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    let data = JSON.stringify({
      username: username,
      password: password,
    });

    try {
      const result = await axios.post(`${BASE_URL}/api/auth/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      login(result.data.token);
      navigate("/");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center h-100">
      <Card style={{ width: "400px" }}>
        <Card.Body>
          {/* <Card.Title className="text-center">Login</Card.Title> */}
          <div className="text-center my-2">
            <Image src={logo} fluid />
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                ref={usernameRef}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-4">
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LoginPage;
