import React from "react";
import { Nav, Navbar } from "react-bootstrap";
// import { useAuth } from "../AuthProvider";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function Menu() {
  const { logout } = useAuth();
  const handleLogoutClick = () => {
    if (window.confirm("คุณต้องการที่จะออกจากระบบหรือไม่?")) {
      logout();
    }
  };

  return (
    <Navbar
      bg="primary"
      variant="dark"
      className="d-flex flex-column p-2 position-fixed h-100"
      style={{ width: 200 }}
    >
      <Navbar.Brand>
        <h4>DX Dashboard</h4>
        <p>({new Date().toLocaleTimeString("th")})</p>
      </Navbar.Brand>
      <Nav className="me-auto d-flex flex-column">
        <NavLink
          exact="true"
          to="/"
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/tfg_report"
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
        >
          TFG Report
        </NavLink>
        <NavLink
          to="/ssl"
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
        >
          SSL Track
        </NavLink>
        <div className="btn nav-link text-start" onClick={handleLogoutClick}>
          Logout
        </div>
      </Nav>
    </Navbar>
  );
  //   return (
  //     <Navbar bg="primary" variant="dark">
  //       <Container>
  //         <Navbar.Brand>
  //           <h3>DX Dashboard ({new Date().toLocaleTimeString()})</h3>
  //         </Navbar.Brand>
  //         <Nav className="me-auto">
  //           <NavLink
  //             exact="true"
  //             to="/"
  //             className={({ isActive }) =>
  //               isActive ? "nav-link active" : "nav-link"
  //             }
  //           >
  //             Dashboard
  //           </NavLink>
  //           <NavLink
  //             to="/report"
  //             className={({ isActive }) =>
  //               isActive ? "nav-link active" : "nav-link"
  //             }
  //           >
  //             Report
  //           </NavLink>
  //           <NavLink
  //             to="/ssl"
  //             className={({ isActive }) =>
  //               isActive ? "nav-link active" : "nav-link"
  //             }
  //           >
  //             SSL Track
  //           </NavLink>
  //           <div className="btn nav-link" onClick={handleLogoutClick}>
  //             Logout
  //           </div>
  //         </Nav>
  //       </Container>
  //     </Navbar>
  //   );
}
