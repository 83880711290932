import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading, currentUser } = useAuth();
  if (isLoading) return <Loading />;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace={true} />;
  }
  return <>{children}</>;
}
