import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TFG_API_BASE_URL } from "../App";
import Chart from "react-google-charts";

export const o = {
  //   title: "รายได้โดยประมาณย้อนหลัง 6 เดือน",
  bar: { groupWidth: "80%" },
  legend: { position: "none" },
};

export default function MonthlyReport() {
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      const result = await axios.get(
        `${TFG_API_BASE_URL}/report/monthly_report`
      );
      // setData(result.data.data);

      const d = result.data.data;

      let c = [];
      c.push([
        "Element",
        "จำนวนคัน",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ]);
      d.map((item) => {
        c.push([
          item.monthy,
          Number(item.total_truck),
          "#0d6efd",
          Number(item.total_truck * 300).toLocaleString("th"),
        ]);
      });
      setData(c);
    } catch (error) {}
  };
  return (
    <Container className="my-2">
      {/* <h4 className="mb-0">รายได้โดยประมาณย้อนหลัง 6 เดือน</h4> */}
      {data.length > 0 && (
        <Chart
          chartType="BarChart"
          width="100%"
          height="200px"
          data={data}
          options={o}
        />
      )}
    </Container>
  );
}
