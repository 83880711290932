import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { TFG_API_BASE_URL } from "../App";
import Chart from "react-google-charts";
import Loading from "../components/Loading";
import TruckCompany from "../components/TruckCompany";
import DxHeader from "../components/Menu";
import MonthlyReport from "../components/MonthlyReport";

function TfgReportPage() {
  const [data, setData] = useState(null);
  const [dataPineChart, setDataPineChart] = useState(null);
  const [totalTruck, setTotalTruck] = useState(0);
  const [totalTruckDup, setTotalTruckDup] = useState(0);
  const [months, setMonths] = useState([]);
  const [monthValue, setMonthValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState(1);
  const [dataTruckCompany, setDataTruckCompany] = useState([]);

  useEffect(() => {
    formatMonth();
  }, []);

  useEffect(() => {
    formatDataPieChart(data);
  }, [chartType]);

  const formatMonth = () => {
    let monval = "";
    let m = new Date();
    let allMonth = [];
    for (let index = 0; index < 12; index++) {
      let month = new Date();
      month.setMonth(month.getMonth() - index);
      month.setDate(1);
      allMonth.push(formatDate(month));
      //   allMonth.push(formatDate(month.toLocaleDateString()));

      if (index == 0 && monthValue == null) {
        // if (index == 0 && monthValue == null) {
        setMonthValue(formatDate(month));
        monval = formatDate(month);
      }
    }

    setMonths(allMonth);
    getReport(monthValue || monval);
    getReportTruckCompany(monthValue || monval);
  };

  const getReport = async (monval) => {
    setLoading(true);
    const result = await axios.post(
      `${TFG_API_BASE_URL}/APIDX/get_report_data_json/${monval}`
    );
    setLoading(false);

    let allTruckFleet = 0;
    let countDup = 0;

    const aa = result.data.map((item) => {
      const report = item.report.map((r) => {
        return {
          ...r,
          total_transport: r.truck.reduce((accumulator, truck) => {
            return accumulator + truck.numtransport;
          }, 0),
        };
      });

      let totalTruck = item.report.reduce((acc1, report) => {
        return acc1 + report.truck.length;
      }, 0);

      item.report.map((d) => {
        d.truck.map((t) => {
          countDup += t.ck;
        });
      });

      //   totalTruck = totalTruck - countDup;
      //   setTotalTruckDup(countDup);
      allTruckFleet += totalTruck;

      return {
        ...item,
        report,
        total_truck: totalTruck,
      };
    });

    setData(aa);
    setTotalTruck(allTruckFleet - countDup);
    setTotalTruckDup(countDup);

    formatDataPieChart(aa);
    // setChartType(1);

    // console.log(`chartType ${chartType}`);
  };
  const getReportTruckCompany = async (monval) => {
    setLoading(true);
    const result = await axios.post(
      `${TFG_API_BASE_URL}/report/get_report/${monval}`
    );
    setDataTruckCompany(result.data.data);
    setLoading(false);
  };

  function formatDataPieChart(result) {
    if (result) {
      let allCompanyKey = [];
      let allCompany = [];
      result.map((dc) => {
        dc.report.map((sub) => {
          const name_trim = removeSpaces(sub.name.split("-")[0]);
          // const name_trim = sub.name.split("-")[0].replace(" ", "").trim()
          // console.log(name_trim)
          if (!allCompanyKey[name_trim]) {
            allCompanyKey[name_trim] = name_trim;

            allCompany.push({
              title: name_trim,
              title_full: sub.name.split("-")[0],
            });
          }

          let obj = allCompany.find((c) => c.title === name_trim);
          if (obj) {
            if (!obj.dc) {
              obj.dc = [];
            }
            obj.dc.push({
              dc: removeSpaces(dc.name.split("-")[1]),
              total: sub.total_transport,
              trucks: sub.truck.length,
            });
          }
        });
      });

      let dcName = result.map((item) => removeSpaces(item.name.split("-")[1]));
      const resultChart = [];

      // เริ่มแถวแรกของตาราง
      const headerRow = ["DCN"];
      allCompany.forEach((company) => {
        headerRow.push(company.title);
      });
      resultChart.push(headerRow);

      // เพิ่มข้อมูลในแต่ละแถวของตาราง
      dcName.forEach((factoryName) => {
        const rowData = [factoryName];
        allCompany.forEach((company) => {
          let total = 0;
          company.dc.forEach((dc) => {
            if (dc.dc === factoryName) {
              total = chartType == 1 ? dc.trucks : dc.total;
            }
          });
          rowData.push(total);
        });
        resultChart.push(rowData);
      });

      setDataPineChart(resultChart);
    }
  }

  function formatDate(inputDate) {
    let day = inputDate.getDate();
    let month = inputDate.getMonth() + 1; // Month is zero-based, so add 1
    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    const year = inputDate.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const removeSpaces = (inputString) => {
    // Use a regular expression to match all spaces (including Thai spaces)
    // and replace them with an empty string
    return inputString.replace(/\s/g, "");
  };

  const onChangeMonth = (value) => {
    setMonthValue(value);
    getReport(value);
    getReportTruckCompany(value);
    setDataPineChart(null);
  };

  const handleClickReport = () => {
    const url = `${TFG_API_BASE_URL}/APIDX/exprotReport/${monthValue}`;
    window.open(url, "_blank");
  };
  const handleClickReportDetail = () => {
    const url = `${TFG_API_BASE_URL}/APIDX/exportDeail/${monthValue}`;
    window.open(url, "_blank");
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <Container fluid className="p-2">
      <MonthlyReport />
      <Container className="d-flex flex-row mb-2 mt-2">
        <div>
          <h3>เดือน</h3>
        </div>
        <div className="mx-2">
          <select
            className="form-select"
            onChange={(e) => onChangeMonth(e.target.value)}
            defaultValue={monthValue}
          >
            {months.map((month, i) => {
              return (
                <option key={month} value={month}>
                  {month.substring(0, month.length - 3)}
                </option>
              );
            })}
          </select>
        </div>
        {loading && <Spinner animation="grow" variant="primary" />}
        <h3 className="ms-2">
          ยอดเรียกเก็บโดยประมาณ {totalTruck} คัน ={" "}
          {(totalTruck * 300).toLocaleString()} บาท{" "}
          {`(รถซ้ำ ${totalTruckDup} คันไม่คิดเงิน)`}
        </h3>
      </Container>
      <Container>
        <Tabs
          defaultActiveKey="dc"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="dc" title="Warehouse">
            <div className="mt-2 d-flex flex-row">
              <Button
                onClick={() => handleClickReport()}
                variant="success"
                className="me-2 d-flex justify-content-center"
              >
                <span className="material-symbols-outlined">download</span>
                รายงานเพื่อวางบิลรายเดือน
              </Button>
              <Button
                onClick={() => handleClickReportDetail()}
                className="me-2 d-flex justify-content-center"
                variant="success"
              >
                <span className="material-symbols-outlined">download</span>
                รายละเอียดทะเบียนรถ
              </Button>
            </div>

            <Row>
              <div className="d-flex flex-row justify-content-center">
                <Button
                  className="mx-2"
                  variant="primary"
                  disabled={chartType == 1}
                  onClick={() => setChartType(1)}
                >
                  รายคัน
                </Button>
                <Button
                  className="mx-2"
                  variant="primary"
                  disabled={chartType == 2}
                  onClick={() => setChartType(2)}
                >
                  รายเที่ยว
                </Button>
              </div>
              {dataPineChart && (
                <Chart
                  chartType="ColumnChart"
                  data={dataPineChart}
                  // width={"100%"}
                  height={"250px"}
                />
              )}
            </Row>

            <Row>
              {data.map((item, index) => {
                return (
                  <Col md="6" key={item.oid} className="mt-2 equal-height-col">
                    <Card>
                      <Card.Body>
                        <Card.Title className="text-center">
                          {item.name.split("-")[1].split(" ")}
                        </Card.Title>

                        <Table bordered>
                          <thead className="bg-light">
                            <tr>
                              <th>บริษัทรถร่วม</th>
                              <th className="text-center">คัน</th>
                              <th className="text-center">เที่ยว</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.report.map((company) => (
                              <tr key={company.oid}>
                                <td>{company.name.split("-")[0]}</td>
                                <td className="text-end">
                                  {company.truck.length}
                                </td>
                                <td className="text-end">
                                  <div>
                                    {company.total_transport.toLocaleString()}
                                  </div>
                                </td>
                              </tr>
                            ))}

                            <tr className="report">
                              <td>สรุป</td>
                              <td className="text-end">
                                {item.total_truck.toLocaleString()}
                              </td>
                              <td className="text-end">
                                {item.report
                                  .reduce((acc1, report) => {
                                    return acc1 + report.total_transport;
                                  }, 0)
                                  .toLocaleString()}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Tab>
          <Tab eventKey="truck_company" title="Truck Company">
            <TruckCompany data={dataTruckCompany} />
          </Tab>
        </Tabs>
      </Container>
    </Container>
  );
}

export default TfgReportPage;
