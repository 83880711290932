import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
    RouterProvider
} from "react-router-dom";
import "./MainStyle.css";
import router from "./router";

export const BASE_URL = "https://dashboard.dxplace.com";
// export const BASE_URL = 'http://localhost:8888/index.php'
export const TFG_API_BASE_URL = "https://api-tfg.dxtms.com";
// export const TFG_API_BASE_URL = "https://api-tfg.dxtms.com/APIDX";

export default function App() {
  return <RouterProvider router={router} />;
}
