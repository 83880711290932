import React, { useEffect } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

export default function TruckCompany({ data }) {
  useEffect(() => {
    console.log(data);
    return () => {};
  }, []);

  return (
    <Container>
      <Row>
        {data.map((item) => {
          return (
            <Col md="6" key={item.title} className="mt-2 equal-height-col">
              <Card>
                <Card.Body>
                  <Card.Title className="">{item.title}</Card.Title>

                  <Table bordered>
                    <thead className="bg-light">
                      <tr>
                        <th>โรงงาน</th>
                        <th className="text-center">คัน</th>
                        <th className="text-center">เที่ยว</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.plants.map((dc) => (
                        <tr key={dc.dc_oid}>
                          <td>{dc.dc_title.split("-")[1]}</td>
                          <td className="text-end">
                            <div>{dc.trucks.length.toLocaleString()}</div>
                          </td>
                          <td className="text-end">
                            <div>{dc.transports.length.toLocaleString()}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
