import { useState, useEffect } from "react";
import { BASE_URL } from "../App";
import { useLocalStorage } from "./useLocalStorage";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [storedValue, setStoredValue] = useLocalStorage("user", null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        if (storedValue) {
          // Send the token to the backend for validation
          const response = await fetch(`${BASE_URL}/api/token/validate_token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${storedValue}`, // Include the token in the request headers
            },
          });

          if (response.ok) {
            const authenticatedUser = await response.json();

            setCurrentUser(authenticatedUser.data);
          } else {
            // Token validation failed, clear the stored token
            setStoredValue(null);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setLoading(false);
      }
    };

    checkToken();
  }, [storedValue, setStoredValue]);

  const login = (token) => {
    setStoredValue(token);
    setCurrentUser(token);
  };

  const logout = () => {
    setStoredValue(null);
    setCurrentUser(null);
    navigate("/login");
  };

  return { isAuthenticated: !!storedValue, isLoading: loading, login, logout };
};

export default useAuth;

// import { useState, useEffect } from "react";
// import { useLocalStorage } from "./useLocalStorage";
// import { BASE_URL } from "../App";
// import { useLocation, useNavigate } from "react-router-dom";

// const useAuth = () => {
//   const [loading, setLoading] = useState(true);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [storedValue, setStoredValue] = useLocalStorage("user", null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   useEffect(() => {
//     const checkToken = async () => {
//       try {
//         if (storedValue) {
//           // Send the token to the backend for validation
//           const response = await fetch(`${BASE_URL}/api/token/validate_token`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${storedValue}`, // Include the token in the request headers
//             },
//           });

//           if (response.ok) {
//             const authenticatedUser = await response.json();

//             setCurrentUser(authenticatedUser.data);
//           } else {
//             // Token validation failed, clear the stored token
//             setStoredValue(null);
//           }
//         }

//         setLoading(false);
//       } catch (error) {
//         console.error("An error occurred:", error);
//         setLoading(false);
//       }
//     };

//     checkToken();
//   }, [storedValue, setStoredValue]);

//   const login = (token) => {
//     setStoredValue(token);
//     setCurrentUser(token);
//   };

//   const logout = () => {
//     setStoredValue(null);
//     setCurrentUser(null);
//   };

//   return {
//     isAuthenticated: !!currentUser,
//     isLoading: loading,
//     currentUser,
//     login,
//     logout,
//   };
// };

// export default useAuth;

// import { useState, useEffect } from "react";
// import { useLocalStorage } from "./useLocalStorage";
// import { BASE_URL } from "../App";
// const useAuth = () => {
//   const [loading, setLoading] = useState(true);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [storedValue, setValue] = useLocalStorage("user", null);

//   useEffect(() => {
//     async () => {
//       try {
//         // ส่งข้อมูลผู้ใช้ไปยังเซิร์ฟเวอร์เพื่อตรวจสอบความถูกต้อง
//         const response = await fetch(`${BASE_URL}/api/token/validate_token`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ token: storedValue }),
//         });

//         if (response.ok) {
//           const authenticatedUser = await response.json();
//           setCurrentUser(authenticatedUser.data);
//           // navigate(location.pathname);
//         } else {
//           // console.error('Authentication failed');
//           // navigate("/login");
//         }

//         setLoading(false);
//       } catch (error) {
//         console.log(error);
//         setLoading(false);
//         // console.error('An error occurred:', error);
//         //   navigate("/login");
//       }
//     };
//   }, []);

//   //   const auth = async (userData) => {
//   //     try {
//   //       // ส่งข้อมูลผู้ใช้ไปยังเซิร์ฟเวอร์เพื่อตรวจสอบความถูกต้อง
//   //       const response = await fetch(`${BASE_URL}/api/token/validate_token`, {
//   //         method: "POST",
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //         },
//   //         body: JSON.stringify({ token: userData }),
//   //       });

//   //       if (response.ok) {
//   //         const authenticatedUser = await response.json();
//   //         setCurrentUser(authenticatedUser.data);
//   //         // navigate(location.pathname);
//   //       } else {
//   //         // console.error('Authentication failed');
//   //         // navigate("/login");
//   //       }
//   //       setLoading(false);
//   //     } catch (error) {
//   //       // console.error('An error occurred:', error);
//   //       //   navigate("/login");
//   //     }
//   //   };

//   return { isAuthenticated: !!currentUser, isLoading: loading, currentUser };
// };

// export default useAuth;
