import axios from "axios";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CardDashboard from "../components/CardDashboard";
import DxChart from "../components/DxChart";
import DxTable from "../components/DxTable";
import Loading from "../components/Loading";

export default function Dashboard() {
  const [data, setData] = React.useState(null);
  const [data2, setData2] = React.useState(null);

  const [dataDx, setDataDx] = React.useState(null);

  React.useEffect(() => {
    getDashboard();
    // getDxDashboard();
    return () => {};
  }, []);
  const getDxDashboard = async () => {
    const result = await axios.get(
      "https://tms-api.dxplace.com/main/dashboard"
    );
    // console.log(result.data);
    setDataDx(result.data);
  };
  // create function display date format dd-mm-YYYY

  function displayDateFormat(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  const getDashboard = async () => {
    const result = await axios.get(
      "https://dashboard.dxplace.com/index.php/main/test"
    );
    // console.log(result.data);
    setData2(result.data.data2);
    const fullDate = displayDateFormat(new Date());

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayFullDate = displayDateFormat(yesterday);

    let todayJob = 0;
    let todayTransport = 0;

    let yesterdayJob = 0;
    let yesterdayTransport = 0;

    let todayAllJob = 0;
    let todayAllTransport = 0;

    let chartJob = {};
    let chartJob3 = [];
    let chartTransport = {};

    Object.keys(result.data.data).forEach(function (key, index) {
      // console.log(key);

      const jobDateCount = result.data.data[key].job.length;
      const transportDateCount = result.data.data[key].transport.length;

      if (
        result.data.data[key].job[jobDateCount - 1] &&
        result.data.data[key].job[jobDateCount - 1].date == fullDate
      ) {
        todayJob += Number(result.data.data[key].job[jobDateCount - 1].total);
      }

      if (
        result.data.data[key].transport[transportDateCount - 1] &&
        result.data.data[key].transport[transportDateCount - 1].date == fullDate
      ) {
        todayTransport += Number(
          result.data.data[key].transport[transportDateCount - 1].total
        );
      }

      if (
        result.data.data[key].job[jobDateCount - 2] &&
        result.data.data[key].job[jobDateCount - 2].date == yesterdayFullDate
      ) {
        yesterdayJob += Number(
          result.data.data[key].job[jobDateCount - 2].total
        );
      }

      if (
        result.data.data[key].transport[transportDateCount - 2] &&
        result.data.data[key].transport[transportDateCount - 2].date ==
          yesterdayFullDate
      ) {
        yesterdayTransport += Number(
          result.data.data[key].transport[transportDateCount - 2].total
        );
      }

      result.data.data[key].job.map((val) => {
        // console.log(val);
        if (!chartJob[val.date]) {
          chartJob[val.date] = Number(val.total);
        } else if (Number(val.total) > 0) {
          chartJob[val.date] += Number(val.total);
        }

        chartJob3.push([val.date, Number(val.total)]);
      });
      result.data.data[key].transport.map((val) => {
        if (!chartTransport[val.date]) {
          chartTransport[val.date] = Number(val.total);
        } else if (Number(val.total) > 0) {
          chartTransport[val.date] += Number(val.total);
        }
      });
    });

    // console.log();

    let chartJob2 = [];
    Object.keys(chartJob).forEach(function (key, index) {
      // console.log(chartJob[key]);
      chartJob2.push({
        date: key,
        value: chartJob[key],
      });
    });

    let chartTransport2 = [];
    Object.keys(chartTransport).forEach(function (key, index) {
      chartTransport2.push({
        date: key,
        value: chartTransport[key],
      });
    });

    chartJob2 = chartJob2.map((item) => [item.date, item.value]);
    chartTransport2 = chartTransport2.map((item) => [item.date, item.value]);

    setData({
      // ...data,
      data: { ...result.data.data },
      todayJob,
      todayTransport,
      yesterdayJob,
      yesterdayTransport,
      chartJob: chartJob2,
      chartTransport: chartTransport2,
      // chartJob3
    });
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <Container fluid className="p-2">
      {/* <DxHeader /> */}
      <Container style={{ paddingBottom: "16px" }}>
        <Row style={{ paddingTop: "16px" }}>
          <Col>
            <CardDashboard
              title={"Today's Job"}
              jobCount={data?.todayJob || 0}
              last={data?.yesterdayJob || 0}
            />
          </Col>
          <Col>
            <CardDashboard
              title={"Today's Transport"}
              jobCount={data?.todayTransport || 0}
              last={data?.yesterdayTransport || 0}
            />
          </Col>
        </Row>

        <Row style={{ paddingTop: "16px" }}>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="text-primary">{`Job`}</Card.Title>
                <DxChart data={data?.chartJob} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="text-primary">{`Transport`}</Card.Title>
                <DxChart data={data?.chartTransport} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{ paddingTop: "16px" }}>
          <Col>
            <Card>
              <Card.Body>
                <DxTable data={data.data} data2={data2} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
