import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Menu from "./components/Menu";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import TfgReportPage from "./pages/TfgReportPage";
import SslPage from "./pages/SslPage";

function Layout() {
  return (
    <div className="d-flex flex-row">
      <div className="h-100">
        <Menu />
      </div>
      <div style={{ marginLeft: 200 }} className="w-100">
        <Outlet />
      </div>
    </div>
  );
}

const elements = createRoutesFromElements(
  <>
    <Route path="login" element={<LoginPage />} />

    <Route
      path="/"
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
    <Route
      path="tfg_report"
      element={
        <ProtectedRoute>
          <TfgReportPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="ssl"
      element={
        <ProtectedRoute>
          <SslPage />
        </ProtectedRoute>
      }
    />
  </>
);

// const router = createBrowserRouter(elements);
const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },

      {
        path: "/tfg_report",
        element: (
          <ProtectedRoute>
            <TfgReportPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/ssl",
        element: (
          <ProtectedRoute>
            <SslPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
